import { useEffect, useState } from 'react';

export const googleAutocomplete = async (text, options) => {  
  return new Promise((resolve) => {
    if (!text) {
      return resolve([[], 'Need valid user text input']);
    }

    if (typeof window.google === 'undefined') {
      return resolve([[], 'Google places library not detected']);
    }

    try {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { 
          input: text,
          componentRestrictions: { country: 'us' },
          types: ['address'],
          ...options 
        },
        (predictions, status) => {
          resolve([predictions || [], '']);
        }
      );
    } catch (e) {
      console.error('Error in autocomplete:', e);
      resolve([[], e.toString()]);
    }
  });
};

export default function usePlacesAutocomplete(userInput, options) {
  const [predictions, setPredictions] = useState({ predictions: [], error: '' });

  useEffect(() => {
    if (!userInput || userInput.length < 3) {
      return;
    }

    const handleDebounce = setTimeout(async () => {
      try {
        const [nextPredictions, err] = await googleAutocomplete(userInput, options);
        setPredictions({ predictions: nextPredictions || [], error: err });
      } catch (error) {
        setPredictions({ predictions: [], error: error.toString() });
      }
    }, 300);

    return () => clearTimeout(handleDebounce);
  }, [userInput, options]);

  return predictions;
}
