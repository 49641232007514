import React, { useState, useEffect, useRef } from 'react';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import SignaturePad from '../components/Signature';
import generatePdf from '../components/PDFGenerator';
import { parsePhoneNumber } from '../utils/libphonenumber-js.custom';
import usePlacesAutocomplete from '../hooks/usePlacesAutocomplete';

const LostAndDamaged = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const clouflareLossDamageWorker = 'https://casemanagement-form.audibene.workers.dev/loss-damage-form';

	const [formData, setFormData] = useState({
		customerName: '',
		customerPhone: '',
		customerEmail: '',
		customerAddress: '',
		apartmentSuite: '',
		leftHearingAid: false,
		rightHearingAid: false,
		earMolds: false,
		charger: false,
		trialPeriod: false,
		warrantyPeriod: false,
		executedDate: ''
	});
	const [customerSignature, setCustomerSignature] = useState('');
	const [customerPhoneError, setCustomerPhoneError] = useState('');
	const [customerEmailError, setCustomerEmailError] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [successMessageColor, setSuccessMessageColor] = useState('');
	const [signatureError, setSignatureError] = useState('');
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [loading, setLoading] = useState(false);
	const customerPhoneInput = useRef(null);
	const customerEmailInput = useRef(null);
	const signaturePadRef = useRef(null);
	const addressInputRef = useRef(null);

	const [userInput, setUserInput] = useState('');
	const { predictions, error } = usePlacesAutocomplete(userInput);

	const validatePhoneNumber = (phoneNumberString, setError) => {
		const phoneNumber = parsePhoneNumber(phoneNumberString, 'US');
		if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'US') {
			setError('');
			return true;
		} else {
			setError('is-warning');
			return false;
		}
	};

	const validateEmail = (email, setError) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(email)) {
			setError('');
			return true;
		} else {
			setError('is-warning');
			return false;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const signature = signaturePadRef.current.getSignature();
		if (!signature) {
			setSignatureError('Please sign before submitting.');
			return;
		}
		setCustomerSignature(signature);
		setSignatureError('');

		const customerPhoneNumberString = customerPhoneInput.current.value;
		const isCustomerPhoneValid = validatePhoneNumber(customerPhoneNumberString, setCustomerPhoneError);

		const customerEmailString = customerEmailInput.current.value;
		const isCustomerEmailValid = validateEmail(customerEmailString, setCustomerEmailError);

		if (!isCustomerPhoneValid) {
			customerPhoneInput.current.focus();
			return;
		} else if (!isCustomerEmailValid) {
			customerEmailInput.current.focus();
			return;
		}

		setLoading(true);

		const pdfBlob = await generatePdf(formData, signature);

		const formDataToSend = new FormData();
		formDataToSend.append('customerEmail', formData.customerEmail);
		formDataToSend.append('file', pdfBlob, 'lost_and_damaged.pdf');
		formDataToSend.append('executedDate', formData.executedDate);

		try {
			const response = await fetch(clouflareLossDamageWorker, {
				method: 'POST',
				body: formDataToSend
			});

			if (response.ok) {
				resetForm();
				window.location.href = `/success-lossanddamages/`;
			} else {
				setSuccessMessage('There was an error submitting the form. Please try again.');
				setSuccessMessageColor('red');
			}
		} catch (error) {
			setSuccessMessage('There was an error submitting the form. Please try again.');
			setSuccessMessageColor('red');
		} finally {
			setLoading(false);
		}
	};

	const resetForm = () => {
		setFormData({
			customerName: '',
			customerPhone: '',
			customerEmail: '',
			customerAddress: '',
			apartmentSuite: '',
			leftHearingAid: false,
			rightHearingAid: false,
			earMolds: false,
			charger: false,
			trialPeriod: false,
			warrantyPeriod: false,
			executedDate: new Date().toISOString().split('T')[0]
		});
		setCustomerSignature('');
		setCustomerPhoneError('');
		setCustomerEmailError('');
		customerPhoneInput.current.value = '';
		customerEmailInput.current.value = '';
		document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
		document.querySelectorAll('input[type=text]').forEach((el) => (el.value = ''));
		document.querySelectorAll('input[type=tel]').forEach((el) => (el.value = ''));
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === 'checkbox') {
			setFormData({ ...formData, [name]: checked });
		} else {
			setFormData({ ...formData, [name]: value });
		}

		if (name === 'customerAddress') {
			setUserInput(value);
			setShowSuggestions(true);
		}
	};

	useEffect(() => {
		if (Array.isArray(predictions) && predictions.length > 0) {
		}
	}, [predictions]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (addressInputRef.current && !addressInputRef.current.contains(event.target)) {
				setShowSuggestions(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSuggestionClick = (suggestion) => {
		setFormData((prev) => ({
			...prev,
			customerAddress: suggestion.description,
			apartmentSuite: '' // Reset apartment/suite when new address is selected
		}));
		setShowSuggestions(false);
	};

	useEffect(() => {
		const today = new Date();
		const formattedDate = today.toISOString().split('T')[0];
		setFormData((prevData) => ({ ...prevData, executedDate: formattedDate }));
	}, []);

	return (
		<Layout>
			<Seo
				title="Loss & Damage Form"
				description="Report lost or damaged hear.com hearing aids. Complete our easy form for quick assistance and solutions. Reliable support for your hear.com hearing aid needs."
			/>
			<div className="o-row-4p5">
				<div className="o-wrapper o-wrapper--small">
					<div className="o-row-4p5"></div>
					<div className="o-row-2p5">
						<CustomBreadcrumb location={location} crumbs={crumbs} />
					</div>
					<div className="o-row-2">
						<h2 className="c-returns-headline">Loss & Damage Form</h2>
					</div>
					<div className="o-row-2">
						<p className="c-md-p">Please complete and sign this form to submit a loss and damage claim.</p>
					</div>

					<form className="c-loss-form" onSubmit={handleSubmit}>
						<div className="o-row-2">
							<div className="u-flex">
								<div className="c-loss-customer">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-name" className="c-loss-form-info-label">
												Customer Name:
											</label>
											<input
												type="text"
												id="customer-name"
												name="customerName"
												placeholder="Customer Name:"
												className="c-loss-form-info-input"
												required
												value={formData.customerName}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-address" className="c-loss-form-info-label">
												Customer Shipping Address:
											</label>
											<div style={{ position: 'relative' }}>
												<input
													type="text"
													id="customer-address"
													name="customerAddress"
													placeholder="Customer Shipping Address"
													className="c-loss-form-info-input"
													required
													value={formData.customerAddress}
													onChange={handleInputChange}
													autoComplete="off"
												/>
												{predictions?.length > 0 && showSuggestions && (
													<div id="suggestions-dropdown" className="c-address-suggestions-dropdown">
														{predictions.map((suggestion, index) => (
															<div
																key={suggestion.place_id || index}
																className="c-address-suggestion-item"
																onClick={() => handleSuggestionClick(suggestion)}
															>
																{suggestion.description}
															</div>
														))}
													</div>
												)}
											</div>
											{formData.customerAddress && !showSuggestions && (
												<div style={{marginTop: '10px'}}>
												<input
													type="text"
													id="apt-suite"
													name="apartmentSuite"
													placeholder="Apt/Suite Number (Optional)"
													className="c-loss-form-info-input apt-suite-input"
													value={formData.apartmentSuite || ''}
													onChange={(e) => {
														const newAptSuite = e.target.value;
														setFormData((prev) => ({
															...prev,
															apartmentSuite: newAptSuite
														}));
													}}
												/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="c-loss-provider">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-phone" className="c-loss-form-info-label">
												Customer Phone Number:
											</label>
											<input
												ref={customerPhoneInput}
												className={`c-loss-form-info-input ${customerPhoneError ? customerPhoneError : ''}`}
												type="tel"
												id="customer-phone"
												name="customerPhone"
												placeholder="Phone Number +1"
												required
												value={formData.customerPhone}
												onChange={handleInputChange}
											/>
											{customerPhoneError && <p className="c-validation-message">Please enter a valid phone number.</p>}
										</div>
									</div>
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-email" className="c-loss-form-info-label">
												Customer Email Address:
											</label>
											<input
												ref={customerEmailInput}
												className={`c-loss-form-info-input ${customerEmailError ? customerEmailError : ''}`}
												type="email"
												id="customer-email"
												name="customerEmail"
												placeholder="Email Address"
												required
												value={formData.customerEmail}
												onChange={handleInputChange}
											/>
											{customerEmailError && (
												<p className="c-validation-message">Please enter a valid email address.</p>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<p className="c-md-p">
								Below specify the products customer wants to file a Loss and Damage for. By filing for a Loss and Damage
								through hear.com, customer confirms that these products were initially purchased through hear.com and
								that this is the first Loss and Damage claim related to these products as specified.
							</p>
						</div>

						<div className="o-row-2">
							<div className="c-returns-form-group">
								<div className="c-returns-form-group__title">
									<p>Product Specification (Select all that apply):</p>
								</div>
								<div className="c-returns-form-group__two-col">
									<label className={`c-returns-form-group__checkbox`}>
										Left hearing aid
										<input
											type="checkbox"
											name="leftHearingAid"
											checked={formData.leftHearingAid}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className={`c-returns-form-group__checkbox`}>
										Right hearing aid
										<input
											type="checkbox"
											name="rightHearingAid"
											checked={formData.rightHearingAid}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className={`c-returns-form-group__checkbox`}>
										Ear Molds
										<input type="checkbox" name="earMolds" checked={formData.earMolds} onChange={handleInputChange} />
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className={`c-returns-form-group__checkbox`}>
										Charger
										<input type="checkbox" name="charger" checked={formData.charger} onChange={handleInputChange} />
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<div className="c-returns-form-group">
								<div className="c-returns-form-group__title">
									<p>Trial & Warranty Information:</p>
								</div>
								<div className="c-returns-form-group__two-col c-returns-form-group__two-col--lossanddamage">
									<label className={`c-returns-form-group__checkbox`}>
										Products are within 45-day trial period
										<input
											type="checkbox"
											name="trialPeriod"
											checked={formData.trialPeriod}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className={`c-returns-form-group__checkbox`}>
										Products are within Loss & Damage warranty period
										<input
											type="checkbox"
											name="warrantyPeriod"
											checked={formData.warrantyPeriod}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
								</div>
							</div>
						</div>
						<div className="o-row-2">
							<div className="c-returns-form-group">
								<div className="c-returns-form-group__title">
									<p>Customer Acknowledgement:</p>
								</div>
								<div className="c-returns-form-group__two-col c-returns-form-group__two-col--lossanddamage">
									<ul>
										<li>
											Any Loss & Damage claim during the 45-day trial period will waive the remaining portion of the
											trial period.
										</li>
										<li>
											Once the manufacturer replaces the initial devices, the replacement devices will not be able to be
											returned or exchanged.
										</li>
										<li>
											Products delivered under Loss & Damage do not come with a separate or additional warranty period.
										</li>
										<li>
											Products delivered under Loss & Damage do not extend the warranty of the initial devices
											purchased.
										</li>
										<li>
											If customer finds lost/damaged device(s) after ordering replacement devices, the lost/damage
											device(s) will not be eligible for service/repair and the customer must surrender the lost/damage
											device(s) to the manufacturer
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<div className="c-returns-form-group">
								<div className="c-returns-form-group__title">
									<p>Fees:*</p>
								</div>
								<div className="c-returns-form-group__two-col c-returns-form-group__two-col--lossanddamage">
									<ul>
										<li>Loss & Damage replacement fee per hearing aid $250</li>
										<li>Loss & Damage service provider fee $150 per instance</li>
										<li>Standard Ear Molds $75 per mold | Power Ear Molds $150 per mold</li>
										<li>
											Loss & Damage fee does not include replacement of charger. If a new charger is ordered, an
											additional fee of $149 will be assessed.
										</li>
										<li>Taxes may apply.</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<p className="c-md-p">
								<strong>
									The fees associated with a Loss & Damage claim are final and non-refundable, including any applicable
									taxes. Filing a Loss & Damage claim voids any remaining trial periods for that device and the sale is
									final.
								</strong>
							</p>
						</div>
						<div className="o-row-2">
							<p className="c-md-p">
								<strong>
									By signing below, customer agrees to the above-mentioned terms and authorizes the payment for the loss
									and damage fees to the card on file.
								</strong>
							</p>
						</div>
						<div className="o-row-2">
							<p className="c-md-p" style={{ fontSize: '12px' }}>
								<strong>
									*If the customer desires a different payment method than we have on file, please call hear.com
									Customer Service to check eligibility at:<br /> (888) 780-3450.
								</strong>
							</p>
						</div>

						<div className="o-row-2">
							<div className="u-flex">
								<div className="c-loss-customer">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-signature" className="c-loss-form-info-label">
												Customer Signature:
											</label>
											<SignaturePad
												ref={signaturePadRef}
												setSignature={setCustomerSignature}
												clearSignatureError={() => setSignatureError('')}
											/>
											{signatureError && <p style={{ color: 'red' }}>{signatureError}</p>}
										</div>
									</div>
								</div>
								<div className="c-loss-provider">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="executed-date" className="c-loss-form-info-label">
												Executed on date of
											</label>
											<input
												type="date"
												id="executed-date"
												name="executedDate"
												className="c-loss-form-info-input"
												value={formData.executedDate}
												readOnly
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<button type="submit" className="c-cta c-cta--desktop-mobile">
								Submit
							</button>
						</div>
						{loading && <div className="c-loss-spinner"></div>}
						{successMessage && <p style={{ color: successMessageColor, textAlign: 'center' }}>{successMessage}</p>}
					</form>
				</div>
			</div>
		</Layout>
	);
};

export default LostAndDamaged;
