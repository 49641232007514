import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { hearComLogoBase64 } from '../hearComLogoBase64';

const generatePdf = (formData, customerSignature) => {
	return new Promise((resolve, reject) => {
		try {
			const doc = new jsPDF();
			const logoWidth = 30;
			const logoHeight = 12;
			const pageWidth = doc.internal.pageSize.getWidth();
			const margin = 10;
			const spacing = 5; // Adjusted spacing for less space between paragraphs
			let startY = margin;

			// Add logo
			doc.addImage(hearComLogoBase64, 'PNG', pageWidth - logoWidth - margin, startY, logoWidth, logoHeight);

			// Title
			doc.setFontSize(16);
			doc.text('Loss & Damage Form', margin, startY + logoHeight / 2 + 5);
			startY += logoHeight + spacing;

			// Add basic form data
			doc.autoTable({
				startY: startY,
				head: [['Field', 'Value']],
				body: [
					['Customer Name', formData.customerName],
					['Customer Phone', formData.customerPhone],
					['Customer Shipping Address', formData.customerAddress],
					['Apt/Suite Number', formData.apartmentSuite],
					['Customer Email Address', formData.customerEmail]
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Introductory text before product specifications
			doc.setFontSize(8);
			const productSpecIntroText = `
        Below specify the products customer wants to file a Loss and Damage for. 
        By filing for a Loss and Damage through hear.com, customer confirms that these products were initially purchased through hear.com 
        and that this is the first Loss and Damage claim related to these products as specified.
      `;
			const splitProductSpecIntroText = doc.splitTextToSize(productSpecIntroText, pageWidth - 2 * margin);
			splitProductSpecIntroText.forEach((line) => {
				doc.text(line, margin, startY);
				startY += 5;
			});

			// Add product specifications
			doc.autoTable({
				startY: startY,
				head: [['Product Specification', 'Selected']],
				body: [
					['Left hearing aid', formData.leftHearingAid ? 'Yes' : 'No'],
					['Right hearing aid', formData.rightHearingAid ? 'Yes' : 'No'],
					['Ear Molds', formData.earMolds ? 'Yes' : 'No'],
					['Charger', formData.charger ? 'Yes' : 'No']
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Trial & Warranty Information section with new color header
			doc.autoTable({
				startY: startY,
				head: [['Trial & Warranty Information', 'Selected']],
				body: [
					['Products are within 45-day trial period', formData.trialPeriod ? 'Yes' : 'No'],
					['Products are within Loss & Damage warranty period', formData.warrantyPeriod ? 'Yes' : 'No']
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Customer Acknowledgement section with new color header
			doc.autoTable({
				startY: startY,
				head: [['Customer Acknowledgement']],
				body: [
					[
						{
							content: `
            - Any Loss & Damage claim during the 45-day trial period will waive the remaining portion of the trial period.
            - Once the manufacturer replaces the initial devices, the replacement devices will not be able to be returned or exchanged.
            - Products delivered under Loss & Damage do not come with a separate or additional warranty period.
            - Products delivered under Loss & Damage do not extend the warranty of the initial devices purchased.
            - If customer finds lost/damaged device(s) after ordering replacement devices, the lost/damaged device(s) 
              will not be eligible for service/repair and the customer must surrender the lost/damaged device(s) to the manufacturer.
            `,
							colSpan: 1,
							styles: { halign: 'left' }
						}
					]
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Fees section with new color header
			doc.autoTable({
				startY: startY,
				head: [['Fees:*']],
				body: [
					[
						{
							content: `
            - Loss & Damage replacement fee per hearing aid $250
            - Loss & Damage service provider fee $150 per instance
            - Standard Ear Molds $75 per mold | Power Ear Molds $150 per mold
            - Loss & Damage fee does not include replacement of charger. If a new charger is ordered, an additional fee of $149 will be assessed.
            - Taxes may apply.
            `,
							colSpan: 1,
							styles: { halign: 'left' }
						}
					]
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Final text and special note
			doc.setFontSize(8);
			const finalText = `
        The fees associated with a Loss & Damage claim are final and non-refundable, including any applicable taxes. 
        Filing a Loss & Damage claim voids any remaining trial periods for that device and the sale is final.
        By signing below, customer agrees to the above-mentioned terms and authorizes the payment for the loss and damage fees to the card on file.
      `;
			const splitFinalText = doc.splitTextToSize(finalText, pageWidth - 2 * margin);
			doc.text(splitFinalText, margin, startY);
			startY += splitFinalText.length * 3;

			// Smaller text for the special note
			doc.setFontSize(6);
			const specialNoteText = `
        *If the customer desires a different payment method than we have on file, please call hear.com Customer Service to check eligibility at: (888) 780-3450.
      `;
			const splitSpecialNoteText = doc.splitTextToSize(specialNoteText, pageWidth - 2 * margin);
			doc.text(splitSpecialNoteText, margin, startY);
			startY += splitSpecialNoteText.length * 2;

			// Adjusted position for signatures and executed date
			const signaturePositionY = startY + 10; // Reduced to pull up the signature and text
			const executedDate = formData.executedDate;

			if (customerSignature) {
				doc.addImage(customerSignature, 'PNG', margin, signaturePositionY, 40, 12);
				doc.text('Customer Signature:', margin, signaturePositionY + 14); // Adjusted position to pull up signature text
			}

			// Add executed date aligned with the signature
			doc.text(`Executed on: ${executedDate}`, pageWidth - 50, signaturePositionY + 14);

			// Convert to Blob and resolve the promise
			const pdfBlob = doc.output('blob');
			resolve(pdfBlob);
		} catch (error) {
			reject(error);
		}
	});
};

export default generatePdf;
